import { Tabs as MuiTabs, TabsProps as MuiTabsProps, styled } from '@mui/material';
import { flatMapDeep } from 'lodash';

import { NavigationTab } from './NavigationTab';
import { NavigationRouteProps } from './types';
import useRouteMatch from './useRouteMatch';

const StyledTabs = styled((props: MuiTabsProps) => (
    <MuiTabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
    minHeight: 40,
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 48,
        width: '100%',
        backgroundColor: 'white',
    },
});

export interface NavigationTabsProps {
    routes?: NavigationRouteProps[];
    orientation?: 'vertical' | 'horizontal';
    onClick?: () => void;
}

export const NavigationTabs = ({ orientation, onClick, routes = [] }: NavigationTabsProps) => {
    const allRoutes = flatMapDeep(routes, 'href');
    const possibleMatch = useRouteMatch(allRoutes);
    const activeRoute = possibleMatch?.pattern?.path ?? false;

    return (
        <StyledTabs orientation={orientation} value={activeRoute}>
            {routes?.map((props: NavigationRouteProps, i: number) => (
                <NavigationTab onClick={onClick} key={i} value={props.href} {...props} />
            ))}
        </StyledTabs>
    );
};
