import { ApolloProvider } from '@apollo/client';
import { theme } from '@c4ads/c4blocks';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { cloneDeep } from 'lodash';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import apolloClient from './api/apollo';
import { AuthProvider } from './api/auth';
import { deeplyReplaceValues } from './helpers';
import './index.css';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE || '');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const cache = createCache({
    key: 'css',
    prepend: true,
});

const preparedTheme = deeplyReplaceValues(cloneDeep(theme), 'fontFamily', 'Inter');
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
    autocapture: {
        dom_event_allowlist: [],
    },
});

root.render(
    <CacheProvider value={cache}>
        <ApolloProvider client={apolloClient}>
            <AuthProvider>
                <ThemeProvider theme={preparedTheme}>
                    <PostHogProvider client={posthog}>
                        <App />
                    </PostHogProvider>
                </ThemeProvider>
            </AuthProvider>
        </ApolloProvider>
    </CacheProvider>
);
