import {
    ListItemButton,
    ListItemText,
    Menu,
    MenuList,
    Tab as MuiTab,
    TabProps as MuiTabProps,
    styled,
} from '@mui/material';
import { SyntheticEvent, useMemo, useState } from 'react';

import { NavigationRouteProps } from './types';

interface StyledTabProps extends MuiTabProps {
    href?: string;
}

const StyledTab = styled((props: StyledTabProps) => <MuiTab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.8)',
    minHeight: 40,
    height: '100%',
    '&:hover': {
        color: 'white',
    },
    '&.Mui-selected': {
        color: 'white',
    },
    '&.Mui-disabled': {
        color: 'white',
        opacity: 0.4,
    },
}));

export interface NavigationTabProps extends MuiTabProps {
    href?: string;
    routes?: NavigationRouteProps[];
    menuTrigger?: 'mouseEnter' | 'click';
}

export const NavigationTab = ({
    routes = [],
    iconPosition = 'start',
    menuTrigger = 'mouseEnter',
    href = undefined,
    ...props
}: NavigationTabProps) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleOpen = (event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const triggerProps = useMemo(() => {
        switch (menuTrigger) {
            case 'mouseEnter':
                return { onMouseEnter: handleOpen };
            case 'click':
                return { onClick: handleOpen };
            default:
                return {};
        }
    }, []);

    return routes.length > 0 ? (
        <>
            <StyledTab
                {...props}
                {...triggerProps}
                href={menuTrigger === 'click' ? undefined : href}
                iconPosition={iconPosition}
            />
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={1}
                sx={{
                    '& .MuiPaper-root': {
                        width: 240,
                    },
                    '& .MuiMenu-list': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                }}
            >
                <MenuList>
                    {routes.map(({ label, href }: NavigationRouteProps) => (
                        <ListItemButton
                            key={href}
                            href={href}
                            sx={{
                                paddingTop: '4px',
                                paddingBottom: '4px',
                            }}
                        >
                            <ListItemText>{label}</ListItemText>
                        </ListItemButton>
                    ))}
                </MenuList>
            </Menu>
        </>
    ) : (
        <StyledTab href={href} iconPosition={iconPosition} {...props} />
    );
};
