import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { LongTextView } from '../../../../../../components/LongTextView';
import { CustomRadioGroup } from '../../../../../../components/RadioGroup';
import { BaseCaseFormPerson } from '../../../../../../types/CaseFormPersons';
import { formatDate } from '../../../utils';
import { FormAutocomplete } from '../../components/Autocomplete';
import { FormDatePicker } from '../../components/FormDatePicker';
import { FormItem } from '../../components/FormItem';
import { MultiFormTemplate } from '../../components/FormTemplates';
import { NotesField } from '../../components/NotesField';
import { DEBOUNCE_WAIT, EMPTY_PLACEHOLDER } from '../../constants';
import { useFormContext } from '../../context/FormsContext';
import { useFormsStore } from '../../store';
import { CourtClerk, courtClerkSchema } from './CourtClerk';
import styles from './Hearings.module.css';
import { Judge, judgeSchema } from './Judge';

const nameFields = {
    firstName: z.string().min(3, 'Required'),
    lastName: z.string().min(3, 'Required'),
};

const defendantSchema = z.object({
    id: z.coerce.string(),
    ...nameFields,
});

const defenseAttorneySchema = z.object({
    id: z.coerce.string(),
    firstName: z.string().min(3, 'Required'),
    lastName: z.string().min(3, 'Required'),
    lawFirm: z.string().nullable().optional(),
    // agencyId: z.string(),
});

const prosecutorSchema = z.object({
    // agencyId: z.string(),
    id: z.coerce.string(),
    firstName: z.string().min(3, 'Required'),
    lastName: z.string().min(3, 'Required'),
    lawFirm: z.string().nullable().optional(),
});

export const schema = z.object({
    id: z.coerce.string().optional(),
    judge: judgeSchema,
    courtClerk: courtClerkSchema.optional(),
    courtId: z.coerce.string(),
    date: z.coerce.date(),
    stage: z.string(),
    happened: z.boolean(), //default=true
    notes: z.string().optional(),

    defendantsPresent: z.array(defendantSchema),
    defenseAttorneysPresent: z.array(defenseAttorneySchema),
    prosecutorsPresent: z.array(prosecutorSchema),

    disconnectDefendantIds: z.string().array(),
    disconnectDefenseAttorneyIds: z.string().array(),
    disconnectProsecutorIds: z.string().array(),
});

type HearingType = z.infer<typeof schema>;
type DefendantType = z.infer<typeof defendantSchema>;
type DefenseAttorneyType = z.infer<typeof defenseAttorneySchema>;
type ProsecutorType = z.infer<typeof prosecutorSchema>;

interface HearingFormProps {
    defaultValues?: HearingType;
    onChange?: (result: HearingType, updateStatus?: boolean) => void;
    onDelete?: () => void;
    onValid?: (isValid: boolean) => void;
}

const defendantPresentOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
];

export const Hearing = memo(
    ({ defaultValues, onChange, onDelete: propOnDelete, onValid }: HearingFormProps) => {
        const { onSubmitListener } = useFormContext();
        const { courts, hearingStages } = useFormsStore((state) => state.lookups);
        const lookupsIsLoading = useFormsStore((state) => state.lookupsIsLoading);

        const hearingsLoading = useFormsStore((state) => state.hearingsLoading);
        const contextEditMode = useFormsStore((state) => state.editMode);
        const originalSectionForms = useFormsStore((state) => state.originalSectionForms);
        const [initialValue] = useState<Partial<HearingType>>({
            happened: true,
            disconnectDefendantIds: [],
            disconnectDefenseAttorneyIds: [],
            disconnectProsecutorIds: [],
            judge: defaultValues?.judge,
            courtClerk: defaultValues?.courtClerk,
            defendantsPresent: defaultValues?.defendantsPresent ?? [],
            defenseAttorneysPresent: defaultValues?.defenseAttorneysPresent ?? [],
            prosecutorsPresent: defaultValues?.prosecutorsPresent ?? [],
            ...defaultValues,
        });
        const {
            control,
            formState: { errors, ...data },
            handleSubmit,
            setValue,
            watch,
        } = useForm<z.infer<typeof schema>>({
            resolver: zodResolver(schema),
            defaultValues: initialValue,
        });

        const [currentDefendantsPresent, currentDefenseAttorneysPresent, currentProsecutorsPresent, date] = watch([
            'defendantsPresent',
            'defenseAttorneysPresent',
            'prosecutorsPresent',
            'date',
        ]);

        const [defenseAttorneyOptions, setDefenseAttorneyOptions] = useState<BaseCaseFormPerson[]>([]);
        const [prosecutorOptions, setProsecutorOptions] = useState<BaseCaseFormPerson[]>([]);

        useEffect(() => {
            const initial = {
                defendantsPresent: (initialValue.defendantsPresent || []).map((item) => item.id),
                defenseAttorneysPresent: (initialValue.defenseAttorneysPresent || []).map((item) => item.id),
                prosecutorsPresent: (initialValue.prosecutorsPresent || []).map((item) => item.id),
            };
            const current = {
                defendantsPresent: (currentDefendantsPresent || []).map((item) => item.id),
                defenseAttorneysPresent: (currentDefenseAttorneysPresent || []).map((item) => item.id),
                prosecutorsPresent: (currentProsecutorsPresent || []).map((item) => item.id),
            };

            const disconnectDefendantIds = initial.defendantsPresent.filter(
                (id) => !current.defendantsPresent.includes(id)
            );
            const disconnectDefenseAttorneyIds = initial.defenseAttorneysPresent.filter(
                (id) => !current.defenseAttorneysPresent.includes(id)
            );
            const disconnectProsecutorIds = initial.prosecutorsPresent.filter(
                (id) => !current.prosecutorsPresent.includes(id)
            );

            setValue('disconnectDefendantIds', disconnectDefendantIds);
            setValue('disconnectDefenseAttorneyIds', disconnectDefenseAttorneyIds);
            setValue('disconnectProsecutorIds', disconnectProsecutorIds);
        }, [currentDefendantsPresent, currentDefenseAttorneysPresent, currentProsecutorsPresent, setValue]);

        useEffect(() => {
            const errorsCount = Object.keys(errors).length;
            onValid?.(errorsCount === 0);
        }, [JSON.stringify(errors)]);

        useEffect(() => {
            let currentData: HearingType;
            const onChangeDebounce = debounce(() => {
                onChange && onChange(currentData);
            }, DEBOUNCE_WAIT);

            const { unsubscribe } = watch((data) => {
                currentData = data as HearingType;
                onChangeDebounce();
            });

            return unsubscribe;
        }, [watch]);

        useEffect(() => {
            return onSubmitListener('hearings', () => {
                return new Promise((resolve) => {
                    handleSubmit(
                        (data) => {
                            resolve({ type: 'payload', result: data });
                        },
                        () => {
                            resolve({ type: 'payload', result: false });
                        }
                    )();
                });
            });
        }, []);

        const editMode = useMemo(() => contextEditMode === 'edit', [contextEditMode]);

        const getCourt = (id?: string) => {
            return courts.find((item) => item.id === id);
        };

        const onDelete = () => {
            propOnDelete && propOnDelete();
        };

        const defendantOptions = useMemo<DefendantType[]>(() => {
            const defendants = originalSectionForms.defendants?.payload || [];

            return defendants.map((defendant, index) => {
                return {
                    // TODO: temporary solution
                    id: defendant.id ?? `${index}`,
                    firstName: defendant.firstName,
                    lastName: defendant.lastName,
                };
            });
        }, [originalSectionForms]);

        useEffect(() => {
            setDefenseAttorneyOptions([
                ...currentDefenseAttorneysPresent.map(({ id, firstName, lastName }) => ({ id, firstName, lastName })),
                { firstName: 'Add', lastName: 'New' },
            ]);

            setProsecutorOptions([
                ...currentProsecutorsPresent.map(({ id, firstName, lastName }) => ({ id, firstName, lastName })),
                { firstName: 'Add', lastName: 'New' },
            ]);
        }, [currentDefenseAttorneysPresent, currentProsecutorsPresent]);

        const stages = useMemo(() => {
            return hearingStages.map((stage) => {
                return {
                    id: stage,
                    label: stage
                        .split('_')
                        .map((item, index) => (index === 0 ? item : item.toLowerCase()))
                        .join(' '),
                };
            });
        }, [hearingStages]);

        const getStage = (id: string) => {
            return stages.find((stage) => stage.id === id);
        };

        const getPersonName = (person?: Partial<BaseCaseFormPerson>) => {
            const nameParts = [person?.firstName, person?.lastName].filter(Boolean);

            return nameParts.join(' ') || EMPTY_PLACEHOLDER;
        };

        const noDefendantsPresentErrorMessage = useMemo(() => {
            return !defendantOptions.length
                ? 'To choose options here you should create at least one defendant in this case form'
                : undefined;
        }, [defendantOptions]);

        const [newAttorneyDialogMode, setNewAttorneyDialogMode] = useState<'defenseAttorney' | 'prosecutor' | null>(
            null
        );

        const handleAddNewAttorney = useCallback(
            (value: { firstName: string; lastName: string }) => {
                if (newAttorneyDialogMode === 'defenseAttorney') {
                    setDefenseAttorneyOptions((prev) => [...prev, value]);
                    setValue('defenseAttorneysPresent', [
                        ...currentDefenseAttorneysPresent,
                        { ...value, id: '', lawFirm: undefined },
                    ]);
                }
                if (newAttorneyDialogMode === 'prosecutor') {
                    setProsecutorOptions((prev) => [...prev, value]);
                    setValue('prosecutorsPresent', [
                        ...currentProsecutorsPresent,
                        { ...value, id: '', lawFirm: undefined },
                    ]);
                }

                setNewAttorneyDialogMode(null);
            },
            [newAttorneyDialogMode, currentDefenseAttorneysPresent, currentProsecutorsPresent]
        );

        return (
            <MultiFormTemplate
                editMode={editMode}
                header={
                    <Controller
                        name="stage"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                errorMessage={errors.stage?.message}
                                isLoading={lookupsIsLoading}
                                editMode={editMode}
                                label="Stage"
                                value={field.value}
                                onChange={(value) => {
                                    field.onChange({
                                        target: { value },
                                    });
                                }}
                                renderValue={(value) => getStage(value)?.label || EMPTY_PLACEHOLDER}
                                renderInput={(value) => (
                                    <FormAutocomplete
                                        disablePortal={false}
                                        errorMessage={errors.stage?.message}
                                        label={null}
                                        options={stages}
                                        value={getStage(value)}
                                        getOptionLabel={(option) => option.label || EMPTY_PLACEHOLDER}
                                        onChange={(_e, newValue) => {
                                            field.onChange({ target: { value: newValue?.id } });
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                }
                formBlocks={[
                    <>
                        <Controller
                            name="date"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.date?.message}
                                    editMode={editMode}
                                    label="Date"
                                    value={field.value}
                                    renderValue={formatDate}
                                    renderInput={(value) => (
                                        <FormDatePicker
                                            errorMessage={errors.date?.message}
                                            value={value}
                                            onChange={(value) => {
                                                const today = new Date().getTime();
                                                if (value?.getTime() > today) {
                                                    setValue('happened', false);
                                                }

                                                field.onChange({ target: { value } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="courtId"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    isLoading={lookupsIsLoading}
                                    editMode={editMode}
                                    label="Court"
                                    errorMessage={errors.courtId?.message}
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                    renderValue={(value) => getCourt(value)?.name || EMPTY_PLACEHOLDER}
                                    renderInput={(value) => (
                                        <FormAutocomplete
                                            disableVirtualization
                                            disablePortal={false}
                                            errorMessage={errors.courtId?.message}
                                            label={null}
                                            options={courts}
                                            getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                            value={getCourt(value)}
                                            onChange={(_e, newValue) => {
                                                field.onChange({ target: { value: newValue?.id } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="happened"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    editMode={editMode}
                                    label="Happened"
                                    value={field.value}
                                    renderValue={(value) => {
                                        return value ? 'Yes' : 'No';
                                    }}
                                    renderInput={(value) => (
                                        <CustomRadioGroup
                                            disabledOptions={date?.getTime() > new Date().getTime() ? ['yes'] : []}
                                            value={value ? 'yes' : 'no'}
                                            onChange={(value) => {
                                                field.onChange({ target: { value: value === 'yes' } });
                                            }}
                                            options={defendantPresentOptions}
                                        />
                                    )}
                                />
                            )}
                        />
                    </>,
                    <Controller
                        name="judge"
                        control={control}
                        render={({ field }) => (
                            <Judge
                                editMode={editMode}
                                initialValues={defaultValues?.judge}
                                onJudge={(value) => {
                                    field.onChange({ target: { value } });
                                }}
                            />
                        )}
                    />,
                    <Controller
                        name="courtClerk"
                        control={control}
                        render={({ field }) => (
                            <CourtClerk
                                editMode={editMode}
                                initialValues={defaultValues?.courtClerk}
                                onCourtClerk={(value) => {
                                    field.onChange({ target: { value } });
                                }}
                            />
                        )}
                    />,
                    <>
                        <Controller
                            name="defendantsPresent"
                            control={control}
                            render={({ field }) => (
                                <FormItem<DefendantType[]>
                                    errorMessage={errors.defendantsPresent?.message || noDefendantsPresentErrorMessage}
                                    isLoading={hearingsLoading}
                                    editMode={editMode}
                                    label="Defendants Present"
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                    renderValue={(value) =>
                                        (value || []).map((item) => `${item.firstName} ${item.lastName}`).join(', ')
                                    }
                                    renderInput={(value) => (
                                        <FormAutocomplete
                                            disablePortal={false}
                                            errorMessage={
                                                errors.defendantsPresent?.message || noDefendantsPresentErrorMessage
                                            }
                                            label={null}
                                            options={defendantOptions}
                                            multiple
                                            getOptionKey={(option) => option.id}
                                            getOptionLabel={(option) => getPersonName(option)}
                                            value={value}
                                            // value={getDefendants((value || []).map((item) => item.id))}
                                            onChange={(_e, newValue) => {
                                                field.onChange({
                                                    target: { value: newValue || [] },
                                                });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="defenseAttorneysPresent"
                            control={control}
                            render={({ field }) => (
                                <FormItem<DefenseAttorneyType[]>
                                    errorMessage={errors.defenseAttorneysPresent?.message}
                                    isLoading={lookupsIsLoading}
                                    editMode={editMode}
                                    label="Defense Attorneys Present"
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                    renderValue={(value) =>
                                        value
                                            .map(
                                                (item) =>
                                                    `${item.firstName} ${item.lastName} ${
                                                        item.lawFirm ? '(' + item.lawFirm + ')' : ''
                                                    }`
                                            )
                                            .join(', ')
                                    }
                                    renderInput={(value) => (
                                        <FormAutocomplete
                                            disablePortal={false}
                                            errorMessage={errors.defenseAttorneysPresent?.message}
                                            label={null}
                                            options={defenseAttorneyOptions}
                                            multiple
                                            getOptionLabel={(option) => getPersonName(option)}
                                            value={value}
                                            // value={getDefenseAttorneys((value || []).map((item) => item.id))}
                                            onChange={(_e, newValue) => {
                                                if (
                                                    newValue?.find(
                                                        ({ firstName, lastName }) =>
                                                            firstName === 'Add' && lastName === 'New'
                                                    )
                                                ) {
                                                    setNewAttorneyDialogMode('defenseAttorney');
                                                } else {
                                                    field.onChange({
                                                        target: { value: newValue || [] },
                                                    });
                                                }
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />

                        <Controller
                            name="prosecutorsPresent"
                            control={control}
                            render={({ field }) => (
                                <FormItem<ProsecutorType[]>
                                    errorMessage={errors.prosecutorsPresent?.message}
                                    isLoading={lookupsIsLoading}
                                    editMode={editMode}
                                    label="Prosecutors Present"
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                    renderValue={(value) =>
                                        value
                                            .map(
                                                (item) =>
                                                    `${item.firstName} ${item.lastName} ${
                                                        item.lawFirm ? '(' + item.lawFirm + ')' : ''
                                                    }`
                                            )
                                            .join(', ')
                                    }
                                    renderInput={(value) => (
                                        <FormAutocomplete
                                            disablePortal={false}
                                            errorMessage={errors.prosecutorsPresent?.message}
                                            label={null}
                                            options={prosecutorOptions}
                                            multiple
                                            getOptionLabel={(option) => getPersonName(option)}
                                            value={value}
                                            // value={getProsecutors((value || []).map((item) => item.id))}

                                            onChange={(_e, newValue) => {
                                                if (
                                                    newValue?.find(
                                                        ({ firstName, lastName }) =>
                                                            firstName === 'Add' && lastName === 'New'
                                                    )
                                                ) {
                                                    setNewAttorneyDialogMode('prosecutor');
                                                } else {
                                                    field.onChange({
                                                        target: { value: newValue || [] },
                                                    });
                                                }
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <NewAttorneyDialog
                            mode={newAttorneyDialogMode}
                            handleSubmit={handleAddNewAttorney}
                            handleClose={() => {
                                setNewAttorneyDialogMode(null);
                            }}
                        />
                    </>,
                ]}
                notes={
                    <Controller
                        name="notes"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                errorMessage={errors.notes?.message}
                                className={styles.notesFormItem}
                                editMode={editMode}
                                label="Notes"
                                value={field.value}
                                renderValue={(value) => <LongTextView>{value || ''}</LongTextView>}
                                renderInput={(value) => (
                                    <NotesField
                                        onChange={(e) => {
                                            field.onChange({
                                                target: { value: e.target.value },
                                            });
                                        }}
                                        value={value}
                                        errorMessage={errors.notes?.message}
                                    />
                                )}
                            />
                        )}
                    />
                }
                onDelete={onDelete}
            />
        );
    },
    /*
        This optimization is possible because the props we pass do not necessarily need to be always up-to-date;
        For such components, initialization is sufficient.
     */
    () => true
);

type NewAttorneyDialogProps = {
    mode: 'defenseAttorney' | 'prosecutor' | null;
    handleClose: () => void;
    handleSubmit: (value: { firstName: string; lastName: string }) => void;
};

function NewAttorneyDialog({ mode, handleClose, handleSubmit }: NewAttorneyDialogProps) {
    const [value, setValue] = useState({ firstName: '', lastName: '' });

    const onSubmit = () => {
        handleSubmit(value);
        handleClose();
        setValue({ firstName: '', lastName: '' });
    };

    return (
        <Dialog open={Boolean(mode)} onClose={handleClose}>
            <DialogTitle>Add New Attorney</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    value={value.firstName}
                    onChange={(event) =>
                        setValue({
                            ...value,
                            firstName: event.target.value,
                        })
                    }
                    label="First Name"
                    type="text"
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="name"
                    value={value.lastName}
                    onChange={(event) =>
                        setValue({
                            ...value,
                            lastName: event.target.value,
                        })
                    }
                    label="Last Name"
                    type="text"
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Cancel</Button>
                <Button onClick={() => onSubmit()}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}
