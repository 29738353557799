import { Tooltip, TooltipProps, Typography } from '@mui/material';

import styles from './CustomTooltip.module.css';

export const CustomTooltip = ({ title, children, ...props }: TooltipProps) => {
    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    className: styles.tooltip,
                },
            }}
            title={typeof title === 'string' ? <Typography className={styles.title}>{title}</Typography> : title}
            {...props}
        >
            {children}
        </Tooltip>
    );
};
