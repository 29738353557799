import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UploadIcon from '@mui/icons-material/Upload';
import { Button, CircularProgress, Tab as MuiTab, Tabs as MuiTabs, Typography } from '@mui/material';
import cn from 'classnames';
import { Ref, forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import { useAuth } from '../../../../api/auth';
import { YesNoDialog } from '../../../../components/YesNoDialog';
import { LAST_ACTIVE_DASHBOARD_TAB } from '../../../../constants/localStorageConstants';
import { DashboardTabsProps, DashboardTabsRefInterface, TabDashboardItem } from '../../../../types/dashboards';
import { DashboardsContext } from '../../DashboardsContext';
import {
    LocalStorageDashboardConfig,
    getDashboardsFromLocalStorage,
    getLastOpenedDashboardTab,
    saveDashboardsToLocalStorage,
} from '../../dashboardsLocalStorageManager';
import styles from './DashboardTabs.module.css';

export const DashboardTabs = forwardRef(
    (
        { onChange: onTabChange, onCleanDashboards, onAddTab, onClose }: DashboardTabsProps,
        ref: Ref<DashboardTabsRefInterface | undefined>
    ) => {
        const { user } = useAuth();
        const { addLocalStorageTask } = useContext(DashboardsContext);
        const [currentTab, setCurrentTab] = useState<number>(getLastOpenedDashboardTab());
        const [dashboardTabs, setDashboardTabs] = useState<TabDashboardItem[]>([]);
        const { openDashboardManager } = useContext(DashboardsContext);
        const [dashboardCloseDialogId, setDashboardCloseDialogId] = useState<string | null>(null);
        const [dragTabId, setDragTabId] = useState<string | null>(null);
        const lastPositionX = useRef<number>(0);

        useImperativeHandle(
            ref,
            () => {
                return {
                    addDashboardTab: (dashboard: TabDashboardItem, replaceExisting: boolean = true) => {
                        setDashboardTabs((prev) => {
                            if (replaceExisting) {
                                const prevClone = [...prev];
                                const index = prevClone.findIndex((item) => item.id === dashboard.id);

                                if (index !== -1) {
                                    prevClone[index] = dashboard;
                                    return prevClone;
                                }

                                return [...prev, dashboard];
                            } else {
                                return [...prev, dashboard];
                            }
                        });
                    },
                    setLoading: (id: string, loading: boolean) => {
                        setDashboardTabs((prev) => {
                            const prevClone = [...prev];

                            const index = prevClone.findIndex((item) => item.id === id);
                            if (index !== -1) {
                                prevClone[index].loading = loading;
                            }

                            return prevClone;
                        });
                    },
                    setSavedStatus: (id: string, status: boolean) => {
                        setDashboardTabs((prev) => {
                            const prevClone = [...prev];

                            const index = prevClone.findIndex((item) => item.id === id);
                            if (index !== -1) {
                                prevClone[index].saved = status;
                            }

                            return prevClone;
                        });
                    },
                    closeTab,
                };
            },
            [setDashboardTabs]
        );

        const addTab = () => {
            onAddTab((tab) => {
                if (!tab) {
                    return;
                } else {
                    const newTabs = [...dashboardTabs, { ...tab }];
                    setDashboardTabs(newTabs);
                    setCurrentTab(newTabs.length - 1);
                    localStorage.setItem(LAST_ACTIVE_DASHBOARD_TAB, `${newTabs.length - 1}`);

                    onTabChange(newTabs[newTabs.length - 1].id);
                }
            });
        };

        const onChange = (index: number, dashboardTabs) => {
            if (dashboardTabs.length - 1 < index) {
                return;
            }
            setCurrentTab(index);
            localStorage.setItem(LAST_ACTIVE_DASHBOARD_TAB, `${index}`);

            onTabChange(dashboardTabs[index].id);
        };

        const closeTab = (tabId?: string) => {
            const id = tabId || dashboardCloseDialogId;
            if (!id) {
                return;
            }
            setDashboardTabs((dashboardTabs) => {
                const index = dashboardTabs.findIndex((tab) => tab.id === id);
                if (index !== -1) {
                    dashboardTabs[index].abort();

                    const dashboardTabsClone = [...dashboardTabs];
                    dashboardTabsClone.splice(index, 1);

                    if (currentTab - 1 >= 0 && currentTab >= index) {
                        onChange(currentTab - 1, dashboardTabsClone);
                    } else {
                        onChange(currentTab, dashboardTabsClone);
                    }

                    if (dashboardTabsClone.length === 0) {
                        onCleanDashboards && onCleanDashboards();
                    }

                    return dashboardTabsClone;
                }
                return dashboardTabs;
            });

            onClose(id);
        };

        const tabDragOnStart = (id: string) => {
            return () => {
                setDashboardTabs((prev) => {
                    onChange(
                        prev.findIndex((item) => item.id === id),
                        prev
                    );
                    return prev;
                });
                setDragTabId(id);
            };
        };

        const tabDragOnStop = () => {
            setDragTabId(null);
            lastPositionX.current = 0;
        };

        const updateTabsOrder = (tabs: TabDashboardItem[]) => {
            addLocalStorageTask(() => {
                if (!user?.id) {
                    return;
                }

                const dashboards = getDashboardsFromLocalStorage(user.id);
                const reorderedDashboards = tabs.map((dashboardItem) =>
                    dashboards.find((item) => {
                        const itemId = item.id;
                        return itemId === dashboardItem.id;
                    })
                );
                saveDashboardsToLocalStorage(user.id, reorderedDashboards as LocalStorageDashboardConfig[]);
            });
        };

        const tabOnDrag = (id: string, index: number) => {
            return (e: DraggableEvent, data: DraggableData) => {
                const { width } = data.node.getBoundingClientRect();

                let positionX = data.x;
                let newIndex = index;

                if (data.x < (width / 2) * -1) {
                    newIndex -= 1;
                    if (newIndex < 0) {
                        newIndex = 0;
                    } else {
                        positionX += width;
                    }
                } else if (data.x > width / 2) {
                    newIndex += 1;
                    if (newIndex <= dashboardTabs.length - 1) {
                        positionX -= width;
                    }
                    if (newIndex > dashboardTabs.length - 1) {
                        newIndex = index;
                    }
                }
                lastPositionX.current = positionX;
                data.node.style.translate = `translateX(${positionX})`;
                if (newIndex !== index) {
                    setCurrentTab(newIndex);
                    localStorage.setItem(LAST_ACTIVE_DASHBOARD_TAB, `${newIndex}`);
                    setDashboardTabs((prevTabs) => {
                        if (newIndex < 0) {
                            newIndex = 0;
                        } else if (newIndex > prevTabs.length - 1) {
                            newIndex = prevTabs.length - 1;
                        }

                        const cloned = prevTabs.slice();
                        const element = cloned.splice(index, 1);
                        data.node.style.translate = ``;

                        const newTabs = cloned
                            .slice(0, newIndex)
                            .concat(element)
                            .concat(cloned.slice(newIndex, prevTabs.length));

                        updateTabsOrder(newTabs);

                        return newTabs;
                    });
                }
            };
        };

        return (
            <div className={styles.container}>
                <Button onClick={openDashboardManager}>
                    <UploadIcon />
                </Button>
                <Button onClick={addTab} className={styles.addButton}>
                    <AddIcon fontSize="small" />
                </Button>
                <YesNoDialog
                    open={!!dashboardCloseDialogId}
                    onClose={() => {
                        setDashboardCloseDialogId(null);
                    }}
                    onConfirm={closeTab}
                    title={
                        <div className={styles.closeDashboardTitleContainer}>
                            <DashboardIcon sx={{ fill: 'var(--text-color-primary)' }} />
                            <Typography variant="h6" className={styles.closeDashboardTitle}>
                                Close dashboard
                            </Typography>
                        </div>
                    }
                    message="Are you sure you want to close this dashboard?"
                />
                <MuiTabs
                    value={currentTab}
                    onChange={(e, index) => {
                        onChange(index, dashboardTabs);
                    }}
                    className={styles.tabs}
                    variant={dragTabId === null ? 'scrollable' : undefined}
                    scrollButtons="auto"
                >
                    {dashboardTabs.map((item, index) => (
                        <Draggable
                            key={item.id}
                            axis="x"
                            onStart={tabDragOnStart(item.id)}
                            onStop={tabDragOnStop}
                            onDrag={tabOnDrag(item.id, index)}
                            position={item.id === dragTabId ? { x: lastPositionX.current, y: 0 } : { x: 0, y: 0 }}
                        >
                            <MuiTab
                                className={cn(
                                    styles.tabWrapper,
                                    index === currentTab && styles.tabWrapperActive,
                                    item.id === dragTabId && styles.draggableTab
                                )}
                                label={
                                    <div className={styles.tab}>
                                        {item.loading && <CircularProgress size={16} />}
                                        {!item.saved && !item.loading && <span className={styles.savedStatus} />}
                                        <Typography title={item.title} variant="subtitle2" className={styles.tabTitle}>
                                            {item.title}
                                        </Typography>
                                        <div
                                            className={styles.closeIconContainer}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                closeTab(item.id);
                                            }}
                                        >
                                            <CloseIcon className={styles.closeIcon} fontSize="small" />
                                        </div>
                                    </div>
                                }
                            />
                        </Draggable>
                    ))}
                </MuiTabs>
            </div>
        );
    }
);
