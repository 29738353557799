import { useRef } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    fontSize: 18,
    fontWeight: '700',
    color: theme.palette.primary.main,
    paddingBottom: 8,
}));

const DialogSubTitle = styled(Typography)(() => ({
    color: 'rgba(90, 114, 144, 0.7)',
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: '700',
    paddingLeft: 24,
    paddingBottom: 16,
}));

const StyledShareButton = styled(Button)(({ theme }) => ({
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 14,
    lineHeight: '17px',
    margin: 5,
    fontWeight: '700',
    width: 100,
    height: 40,
    padding: 15,
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
    },
}));

interface Props {
    open: boolean;
    title?: string;
    onClose: () => void;
    onAgree?: () => void;
    description?: string;
    readonly?: boolean;
}

export default function EndUserLicenseAgreement({ open, title, onClose, onAgree, description, readonly }: Props) {
    const ref = useRef(null);
    // useClickAway(ref, () => onClose());

    const handleAgree = () => {
        onAgree && onAgree();
        onClose();
    };

    return (
        <>
            <Dialog
                open={open}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth={'md'}
                onKeyDown={(e) => e.code === 'Escape' && onClose()}
            >
                <Box ref={ref}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            padding: '8px 0px',
                        }}
                    >
                        <Box>
                            <StyledDialogTitle id="scroll-dialog">{title}</StyledDialogTitle>
                            <DialogSubTitle>{description}</DialogSubTitle>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: '24px' }}>
                            <IconButton
                                aria-label="close"
                                sx={{
                                    marginLeft: 1,
                                }}
                                onClick={onClose}
                            >
                                <CloseIcon color="primary" />
                            </IconButton>
                        </Box>
                    </Box>
                    <DialogContent
                        dividers={false}
                        sx={{
                            minWidth: '300px',
                            maxHeight: 500,
                            background: '#f9f9f9',
                            overflowY: 'auto',
                        }}
                    >
                        <Typography variant="h5" style={{ textAlign: 'center' }}>
                            The Wildlife Defense Platform End User License Agreement
                        </Typography>
                        <Box
                            sx={{
                                width: '100%',
                                my: 2,
                                '& ol>li': {
                                    pb: 1,
                                    pt: 1,
                                },
                            }}
                        >
                            <Typography variant="body1">
                                This End User License Agreement (“Agreement”) is entered into as of the date of
                                electronic signature, including an electronic click to accept, by the User (the
                                “Effective Date”), and is by and between Center For Advanced Defense Studies (“C4ADS”),
                                whose principal place of business is 1201 Eye Street, Suite 200, Washington, D.C. 20005,
                                and Customer. This Agreement contemplates Customer’s and its authorized User’s
                                subscription to C4ADS’ wildlife defense focused database, known as The Wildlife Defense
                                Platform (the “Platform”). By clicking “I Agree,” you (the “User” or “you”) agree to the
                                terms in this EULA on your behalf and on behalf of the C4ADS customer who has registered
                                an account for the Platform, and with which you are employed, affiliated or associated
                                (“Customer”). You represent that you have authority to bind the Customer to these terms.
                                C4ADS and Customer are referred to collectively as the “Parties.” By executing this
                                Agreement, User agrees, and agrees on behalf of Customer, to be bound by all the terms
                                and conditions contained herein.
                            </Typography>
                            <ol>
                                <li>
                                    <Typography variant="h6">Registration and User Account Security</Typography>
                                    <ol>
                                        <li>
                                            <Typography variant="body2">
                                                During registration you will be asked to provide information including
                                                your first and last name, organization, e-mail address, and password.
                                                You are responsible for providing accurate and correct registration
                                                information and C4ADS assumes no liability for any error, inaccuracy or
                                                omission in such information or in the Platform resulting from any
                                                information provided by you. By becoming a registered Customer and
                                                authorized User of our Platform, you will also be prompted to
                                                electronically sign this EULA and give your consent to our processing of
                                                your personal data in accordance with our privacy policy.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                Upon completing the registration process, including signature of this
                                                EULA and consent to the privacy policy, C4ADS will review your
                                                registration. If your registration is accepted, you will be signed up
                                                for the platform.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                Customer and its User are responsible for all actions and transactions
                                                made through your account. You are responsible for maintaining the
                                                confidentiality of your password and you should ensure that you exit
                                                properly from your account at the end of each session. You agree to
                                                notify C4ADS immediately if you suspect any unauthorized use of your
                                                account or access to your password or account.
                                            </Typography>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <Typography variant="h6">Ownership and Subscription Terms</Typography>
                                    <ol>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>C4ADS Ownership</strong>. As between the parties and except for
                                                the limited express license granted to Customer under this EULA, C4ADS
                                                retains ownership of all right, title and interest, including all
                                                related intellectual property rights, in and to the Platform and the
                                                C4ADS Materials. As used in this EULA, “C4ADS Materials” shall mean
                                                means information, know-how, data, data sets, algorithms, software and
                                                other computer programs (in source code, object code or any other
                                                format), technical information, specifications, configuration
                                                information, methods, procedures, techniques and protocols within,
                                                utilized by, or related to the Platform.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Customer Ownership</strong>. As between the parties and except
                                                for the license granted to C4ADS under this EULA, Customer owns all
                                                right, title and interest, including all related intellectual property
                                                rights, in and to the non-public data or non-public information uploaded
                                                to the Platform by Customer or its Users (the “Customer Data”). Customer
                                                may grant Users from other Platform Customer organizations access and
                                                rights to Customer information by opting in to data-sharing through the
                                                Regional Interface.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Subscription</strong>. Customer is subscribing to use the
                                                Platform as software as a service (SaaS). Subject to Customer’s and its
                                                User’s compliance with its obligations under this EULA, C4ADS hereby
                                                grants Customer a non-exclusive, limited, revocable, non-transferable
                                                and non-sublicenseable license to access the Platform only: (a) during
                                                the Subscription Term as set forth herein (b) for Customer’s own
                                                internal business and research purposes, and not for resale or license
                                                to third parties, (c) by up to the authorized number of users, (d)
                                                download certain data from the Platform and (d) subject to any
                                                applicable usage allowances.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Subscription Restrictions</strong>. Except as may be expressly
                                                provided for herein, Customer will not directly or indirectly, nor
                                                authorize any of its Users or any third party to, do any of the
                                                following: (a) copy, modify or create derivative works of the C4ADS
                                                Materials or Platform, (b) sublicense, sell, resell, rent, lease, market
                                                or distribute the C4ADS Materials, data or Platform, or otherwise make
                                                the Platform available to anyone other than its authorized users; (c)
                                                reverse engineer, decompile, disassemble or otherwise attempt to gain
                                                access to the source code form of the C4ADS Materials or Platform; (d)
                                                use the Platform in violation of any applicable law, including export,
                                                intellectual property, libel, and privacy laws; (e) remove any
                                                proprietary notices from the C4ADS Materials, Platform, or any other
                                                materials made available by C4ADS; (f) use or access the Platform or
                                                C4ADS Materials in order to build a competitive product or service; (g)
                                                interfere with or disrupt the integrity or performance of the Platform
                                                or any data contained therein; (h) attempt to gain unauthorized access
                                                to the Platform or its related data, systems or networks. Customer is
                                                responsible for each User’s use of the Platform and compliance with the
                                                terms of the EULA
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Use and Publicity</strong>. C4ADS grants the Customer, solely to
                                                the extent they are publishing information for journalistic, or law
                                                enforcement purposes, permission to use any name or logo of the Platform
                                                in public facing journalistic or law enforcement materials which rely on
                                                information sourced from the Platform. Customer agrees that such use of
                                                information from the Platform shall be accompanied with appropriate
                                                reference to the “The Wildlife Defense Platform by C4ADS” as the source
                                                of such information.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Customer Indemnity Obligations</strong>. Customer will, at its
                                                own cost and expense, defend C4ADS against any Claim made or brought
                                                against C4ADS by a third party, to the extent arising out of or
                                                attributable to (a) Customer’s use of the Platform or data within the
                                                Platform in violation of this EULA, (b) any defamation or related suits
                                                brought against C4ADS related to Customer’s use of the Platform or data
                                                within the Platform, (c) any decision or action taken by Customer based
                                                on the Platform or data within the Platform, or (d) any claim that
                                                Customer did not have the appropriate authority, rights, or consent to
                                                provide data to C4ADS, and indemnify C4ADS for any damages awarded
                                                against C4ADS, or agreed upon by Customer in settlement, and incidental
                                                costs reasonably incurred by C4ADS in connection with the Claim.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Data Security</strong>. C4ADS will provide data security for
                                                data that is entered and stored on the Platform and will regularly
                                                monitor the Platform for suspicious activity. C4ADS will protect data
                                                stored on the Platform using reasonable security measures, which
                                                includes modern security standards for authentication including but not
                                                limited to data encryption at rest and data encryption in transit. C4ADS
                                                will not be held liable in the case that malicious or unauthorized
                                                actors gain unauthorized access to or make public any data stored in the
                                                Platform.
                                            </Typography>
                                        </li>
                                    </ol>
                                </li>
                                <Typography variant="body1">
                                    <strong>Compliance with Applicable Laws and Regulations</strong>. User must access
                                    and use the Platform in compliance with all applicable local, state, federal and/or
                                    national laws of the United States and those of the Customer’s jurisdiction. Users
                                    are strictly prohibited from adding additional users to the Platform if such
                                    addition would result in: (i) a user that is a member of a foreign security force
                                    unit which has committed gross violations of internationally recognized human rights
                                    as defined by 10 U.S.C. §362 22 U.S.C. §2378d (the “Leahy Laws”); or (ii) users
                                    being identified on any list of sanctioned individuals or entities maintained by the
                                    U.S. Department of the Treasury’s Office of Foreign Assets Control (OFAC) or any
                                    other relevant U.S. government agency. By adding any user, you represent and warrant
                                    that such addition complies with all applicable laws, including items (i) and (ii)
                                    above. Any violation of this clause may result in immediate termination of access to
                                    the Platform and potential legal action.
                                </Typography>
                                <li>
                                    <Typography variant="h6">Disclaimer of Warranties</Typography>
                                    <ol>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Disclaimers</strong>. The Platform contains data from sources
                                                all over the world. C4ADS does not and cannot guarantee the accuracy,
                                                timeliness, or completeness of any data made available through the
                                                Platform. Customer agrees that C4ADS is not responsible for the quality,
                                                completeness, timeliness, or accuracy of any of the data made available
                                                through the Platform. Customer acknowledges and agrees that it is the
                                                User’s responsibility to evaluate the accuracy, completeness, or
                                                usefulness of the data, and any information, opinion, or other content
                                                available through the Platform. C4ADS shall not be responsible for any
                                                reduction in data availability from a country resulting from
                                                governmental restrictions in such country. C4ADS DOES NOT MAKE ANY
                                                WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE,
                                                INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                                                FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. C4ADS DOES NOT
                                                WARRANT THE USER’S ACCESS AND USE OF THE PLATFORM WILL BE ERROR-FREE OR
                                                UNINTERRUPTED. C4ADS MAKES NO REPRESENTATIONS AS TO THE DATA IN THE
                                                PLATFORM AND DISCLAIMS ANY AND ALL LIABILITY RELATED TO ANY SUBSEQUENT
                                                ANALYSIS, FINDINGS, CONCLUSIONS, OR ACTIONS TAKEN BY CUSTOMER AS A
                                                RESULT OF THEIR ACCESS TO THE PLATFORM, INCLUDING ANY DEFAMATION ACTIONS
                                                AGAINST CUSTOMER BASED ON THEIR USE OF THE PLATFORM OR THE DATA RESIDING
                                                IN THE PLATFORM.
                                            </Typography>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <Typography variant="h6">Limitation of Liability</Typography>
                                    <ol>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Limitation of Liability</strong>. In no event shall either party
                                                or their agents, principals or licensors be liable to the other party
                                                for incidental, indirect, special or consequential damages (however
                                                arising) including, but not limited to, loss of profit, loss of use,
                                                loss of data, loss of revenues, damages for lost information, lost
                                                savings, lost profits or business interruption, or damages to business
                                                or reputation arising out of the performance or non-performance of any
                                                aspect of this Agreement, whether or not the damaged party shall have
                                                been made aware of the possibility of such loss. Neither Party nor its
                                                agents or principals shall have any liability or responsibility in
                                                respect of actions or decisions of Customer in reliance or based on the
                                                Platform or any data derived therefrom. In no event will the aggregate
                                                liability of C4ADS arising out of or related to this EULA or the
                                                Platform, whether in contract, tort (including negligence) or under any
                                                other theory of liability, exceed one thousand dollars.
                                            </Typography>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <Typography variant="h6">Term and Termination</Typography>
                                    <ol>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Subscription Term</strong>. The Term of this Agreement shall
                                                commence on the Effective Date and shall continue in effect for the
                                                period of one year.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Termination</strong>. C4ADS may terminate this Agreement and the
                                                subscription license granted to User at any time upon giving ten (10)
                                                days’ notice. C4ADS may at any time suspend access to the Platform or
                                                any data stored therein.
                                            </Typography>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <Typography variant="h6">Miscellaneous</Typography>
                                    <ol>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Severability and Interpretation</strong>. If any provision(s) of
                                                this Agreement is held by a court of competent jurisdiction to be
                                                contrary to any applicable law or regulation, the Parties agree that
                                                such provision shall be construed so that it can be found lawful to the
                                                fullest extent possible and the remaining provisions of this Agreement
                                                shall remain in full force and effect. If such provision cannot be
                                                construed in a fashion that is lawful or is otherwise found void, then
                                                the Parties agree that the remaining provisions of the Agreement shall
                                                continue in full force and effect as if said void provision never
                                                existed and as long as the removal of such void provision does not alter
                                                the obligations of the Parties. If an ambiguity or question of intent
                                                arises, this Agreement will be construed as if drafted jointly by the
                                                Parties and no presumption or burden of proof will arise favoring or
                                                disfavoring either Party by virtue of authorship of any of the
                                                provisions of this Agreement.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Governing Law and Venue</strong>. This Agreement shall be
                                                governed by, and construed and enforced in accordance with, the laws of
                                                the District of Columbia, USA without regard to conflict of laws
                                                principles. The Parties hereby submit to the exclusive jurisdiction of
                                                the state and federal courts located in the District of Columbia for the
                                                resolution of all disputes under, arising from or relating to this
                                                Agreement, its performance or otherwise between the Parties, and both
                                                Parties agree that they shall not contest venue in such courts.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Headings</strong>. The section headings used in this Agreement
                                                are for reference and convenience only and shall not enter into the
                                                interpretation of this Agreement.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                <strong>Entire Agreement</strong>. This Agreement contains all the
                                                covenants and agreements between the Parties regarding the Platform.
                                                Each Party to this Agreement acknowledges that no representations,
                                                inducements, promises or agreements, orally or otherwise, have been made
                                                by any Party, or anyone acting on behalf of any Party, which are not
                                                contained herein, and that no other agreement, statement, or promise not
                                                contained in this Agreement shall be valid or binding.
                                            </Typography>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </Box>
                    </DialogContent>
                    {!readonly && (
                        <DialogActions sx={{ justifyContent: 'flex-end', padding: 2 }}>
                            <StyledShareButton onClick={handleAgree}>Agree</StyledShareButton>
                            <StyledShareButton
                                onClick={onClose}
                                variant="outlined"
                                sx={{
                                    background: 'white',
                                    color: '#122945',
                                    '&:hover': {
                                        background: '#eeeeee',
                                    },
                                }}
                            >
                                Decline
                            </StyledShareButton>
                        </DialogActions>
                    )}
                </Box>
            </Dialog>
        </>
    );
}
